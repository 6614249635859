import React, { useState, useRef, useEffect } from 'react';
import { Components, Icons } from '../../../../utils/material-ui';
import styles from './styles';
import {
  API_HANDLE_ERROR,
  API_POST,
  API_PATCH,
  PRE_SEND_URL,
  API_GET,
  API_DELETE
} from '../../../../utils/api';
import { useStateValue } from '../../../../utils/store';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import theme from '../../../../utils/theme';
import axios from 'axios';
import DependentDocs from './DependentDocs';
import DependentViewPersonal from './dependentView';
import ConvertToUpperCase from '../../../../utils/ConvertToUpperCase';
import { getDocsDate } from '../../../../utils/moment';
import DocumentConfirm from './documentConfirm';
import { mobileNumberValidate, nameValidate } from '../../../../utils/formValidation';
import moment from 'moment';
import countryList from '../../../../utils/ListOfAllCountry';
import SampleDocsFile from '../sampleDocsFile';

const {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Autocomplete,
  useMediaQuery
} = Components;

const { CheckCircleOutlineIcon, CancelIcon } = Icons;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid item {...props} />;

const labels = ['Personal Details', 'Documents', 'Confirm'];

// const vacStatusArray = [
//   { name: '1st Dose', value: '1st' },
//   { name: '2nd Dose', value: '2nd' },
//   { name: 'Booster', value: 'booster' }
// ];
const relationArray = ['Father', 'Mother', 'Spouse', 'Son', 'Daughter'];

const MealPreArray = [
  { name: 'Veg', value: 'Veg' },
  { name: 'Non-Veg', value: 'Non-Veg' },
  { name: 'Jain', value: 'Jain' }
];
// const SomkingArray = [
//   { name: 'Smoking', value: 'Smoking' },
//   { name: 'Non Smoking', value: 'Non Smoking' }
// ];

function Dependent({
  classes,
  data,
  formType,
  dependentStatus,
  EditDependent,
  handleStep,
  isEdit,
  dataId,
  addEditDepedentStatus
}) {
  const [, dispatch] = useStateValue();
  const [updateId, setUpdateId] = useState();
  const [step, setStep] = useState(1);
  // const [vacStatus, setVacStatus] = useState('1st');
  const [, setFromSubmit] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState('');
  const [allFamilyMembers, setAllFamilyMembers] = useState([]);
  const [indiviualUserData, setIndiviualUserData] = useState({});
  const [newUploadImage, setNewUploadImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDocUpload, setIsDocUpload] = useState(false);
  const [isOpenSampleDocsModal, setIsOpenSampleDocsModal] = useState(false);
  const [sampleDocsType, setSampleDocsType] = useState('');
  const scrrenSizeSmall = useMediaQuery(theme.breakpoints.up('sm'));

  // stepper steps function

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  // get user info

  const getUserInfo = () => {
    const resultId = data?.role === 'agent' ? data?.agentId : data?.employeeId || data?.uniqueId;
    resultId &&
      API_GET(`travelPackage/getUserInfo/${resultId}?role=${data?.role}`)
        .then((res) => {
          setAllFamilyMembers(res?.data);
          if (isEdit !== 'edit') {
            setSelectedFamilyMember(res?.data && res?.data[res?.data?.length - 1]?._id);
          }
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  // get specific user info

  const getUserDetail = () => {
    selectedFamilyMember &&
      API_GET(`user/getUserDetails?userId=${selectedFamilyMember}&ownerId=${data?._id}`)
        .then((res) => {
          setIndiviualUserData(res?.data?.user);
          setUpdateId(res?.data?.user?._id);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    getUserDetail();
  }, [selectedFamilyMember, data]);

  // handle edit

  const handleEdit = () => {
    EditDependent();
    setUpdateId(indiviualUserData?._id);
  };

  // Initial state

  const [sendData, setSendData] = useState({
    dob: '',
    gender: 'male',
    phone: '',
    name: '',
    relation: '',
    // smokingStatus: '',
    mealpreference: '',
    freefield1: '',
    freefield2: '',
    freefield3: '',
    vaccineStatus1: '1st dose',
    vaccineType1: '',
    vaccineDate1: '',
    nextVaccinationDate1: '',
    vaccineStatus2: '2nd dose',
    vaccineType2: '',
    vaccineDate2: '',
    nextVaccinationDate2: '',
    vaccineStatus3: 'Booster',
    vaccineType3: '',
    vaccineDate3: '',
    nextVaccinationDate3: '',
    aadharNo: '',
    aadhaarAddress: '',
    panNo: '',
    panDOB: '',
    passportNo: '',
    passportCountry: '',
    passportIssueDate: '',
    passportExpiryDate: '',
    visaNo: '',
    visaCountry: '',
    visaType: '',
    visaIssueDate: '',
    visaExpiryDate: '',
    visaEntry: '',
    healthInsurance: '',
    healthInsuranceCompanyName: '',
    healthInsuranceIssueDate: '',
    healthInsuranceExpiryDate: '',
    travelInsurance: '',
    travelInsuranceCountry: '',
    travelInsuranceCompanyName: '',
    travelInsuranceIssueDate: '',
    travelInsuranceExpiryDate: '',
    passportFrontLink: [],
    passportBackLink: [],
    aadhaarFrontLink: [],
    aadhaarBackLink: [],
    visaLink: [],
    travelInsuranceLink: [],
    healthInsuranceLink: [],
    vaccination1stDoseLink: [],
    vaccination2ndDoseLink: [],
    vaccinationBoosterLink: [],
    panCardLink: [],
    additionalDoc1: [],
    additionalDoc2: []
  });

  useEffect(() => {
    const passportDoc = {
      country: indiviualUserData?.passportDocs && indiviualUserData?.passportDocs[0]?.country,
      passportNo: indiviualUserData?.passportDocs && indiviualUserData?.passportDocs[0]?.passportNo,
      issueDate: indiviualUserData?.passportDocs && indiviualUserData?.passportDocs[0]?.issueDate,
      expiryDate: indiviualUserData?.passportDocs && indiviualUserData?.passportDocs[0]?.expiryDate,
      passportFrontLink: [
        indiviualUserData?.passportDocs &&
          indiviualUserData?.passportDocs[0]?.links &&
          indiviualUserData?.passportDocs[0]?.links[0]
      ],
      passportBackLink: [
        indiviualUserData?.passportDocs &&
          indiviualUserData?.passportDocs[0]?.links &&
          indiviualUserData?.passportDocs[0]?.links[1]
      ]
    };

    const aadhaarCardDocs = {
      aadhaarNo:
        indiviualUserData?.aadhaarCardDocs && indiviualUserData?.aadhaarCardDocs[0]?.aadhaarNo,
      aadhaarAddress:
        indiviualUserData?.aadhaarCardDocs && indiviualUserData?.aadhaarCardDocs[0]?.aadhaarAddress,
      dateOfBirth:
        indiviualUserData?.aadhaarCardDocs && indiviualUserData?.aadhaarCardDocs[0]?.dateOfBirth,
      aadhaarFrontLink: [
        indiviualUserData?.aadhaarCardDocs &&
          indiviualUserData?.aadhaarCardDocs[0]?.links &&
          indiviualUserData?.aadhaarCardDocs[0]?.links[0]
      ],
      aadhaarBackLink: [
        indiviualUserData?.aadhaarCardDocs &&
          indiviualUserData?.aadhaarCardDocs[0]?.links &&
          indiviualUserData?.aadhaarCardDocs[0]?.links[1]
      ]
    };

    const visaDocs = {
      country: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.country,
      visaNo: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.visaNo,
      visaType: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.visaType,
      entry: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.entry,
      issueDate: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.issueDate,
      expiryDate: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.expiryDate,
      visaLink: indiviualUserData?.visaDocs && indiviualUserData?.visaDocs[0]?.links
    };

    const travelInsuranceDocs = {
      country:
        indiviualUserData?.travelInsuranceDocs &&
        indiviualUserData?.travelInsuranceDocs[0]?.country,
      registerationNo:
        indiviualUserData?.travelInsuranceDocs &&
        indiviualUserData?.travelInsuranceDocs[0]?.registerationNo,
      issueDate:
        indiviualUserData?.travelInsuranceDocs &&
        indiviualUserData?.travelInsuranceDocs[0]?.issueDate,
      expiryDate:
        indiviualUserData?.travelInsuranceDocs &&
        indiviualUserData?.travelInsuranceDocs[0]?.expiryDate,
      travelInsuranceLink:
        indiviualUserData?.travelInsuranceDocs && indiviualUserData?.travelInsuranceDocs[0]?.links
    };

    const healthInsuranceDocs = {
      companyName:
        indiviualUserData?.healthInsuranceDocs &&
        indiviualUserData?.healthInsuranceDocs[0]?.companyName,
      registerationNo:
        indiviualUserData?.healthInsuranceDocs &&
        indiviualUserData?.healthInsuranceDocs[0]?.registerationNo,
      issueDate:
        indiviualUserData?.healthInsuranceDocs &&
        indiviualUserData?.healthInsuranceDocs[0]?.issueDate,
      expiryDate:
        indiviualUserData?.healthInsuranceDocs &&
        indiviualUserData?.healthInsuranceDocs[0]?.expiryDate,
      healthInsuranceLink:
        indiviualUserData?.healthInsuranceDocs && indiviualUserData?.healthInsuranceDocs[0]?.links
    };

    const panCardDocs = {
      panNumber: indiviualUserData?.panCardDocs && indiviualUserData?.panCardDocs[0]?.panNumber,
      dateOfBirth: indiviualUserData?.panCardDocs && indiviualUserData?.panCardDocs[0]?.dateOfBirth,
      panCardLink: indiviualUserData?.panCardDocs && indiviualUserData?.panCardDocs[0]?.links
    };

    const vaccinationDocs = {
      vaccination1stDocs: {
        vaccinationStatus:
          indiviualUserData?.vaccinationDocs &&
          indiviualUserData?.vaccinationDocs[0]?.vaccinationStatus,
        vaccineType:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[0]?.vaccineType,
        nextDate:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[0]?.nextDate,
        currentDate:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[0]?.currentDate,
        links: indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[0]?.links
      },
      vaccination2ndDocs: {
        vaccinationStatus:
          indiviualUserData?.vaccinationDocs &&
          indiviualUserData?.vaccinationDocs[1]?.vaccinationStatus,
        vaccineType:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[1]?.vaccineType,
        nextDate:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[1]?.nextDate,
        currentDate:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[1]?.currentDate,
        links: indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[1]?.links
      },
      vaccinationBoosterDocs: {
        vaccinationStatus:
          indiviualUserData?.vaccinationDocs &&
          indiviualUserData?.vaccinationDocs[2]?.vaccinationStatus,
        vaccineType:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[2]?.vaccineType,
        nextDate:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[2]?.nextDate,
        currentDate:
          indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[2]?.currentDate,
        links: indiviualUserData?.vaccinationDocs && indiviualUserData?.vaccinationDocs[2]?.links
      }
    };

    if (isEdit === 'edit') {
      const resData = {
        name: indiviualUserData?.fullName,
        phone: indiviualUserData?.mobileNumber,
        dob: indiviualUserData?.dateOfBirth,
        gender: indiviualUserData?.gender,
        relation: indiviualUserData?.relation,
        smokingStatus: indiviualUserData?.smokingStatus,
        mealpreference: indiviualUserData?.mealpreference,
        freefield1: indiviualUserData?.freefield1,
        freefield2: indiviualUserData?.freefield2,
        freefield3: indiviualUserData?.freefield3,
        vaccineStatus1: vaccinationDocs?.vaccination1stDocs?.vaccinationStatus,
        vaccineType1: vaccinationDocs?.vaccination1stDocs?.vaccineType,
        vaccineDate1: vaccinationDocs?.vaccination1stDocs?.currentDate,
        nextVaccinationDate1: vaccinationDocs?.vaccination1stDocs?.nextDate,
        vaccineStatus2: vaccinationDocs?.vaccination2ndDocs?.vaccinationStatus,
        vaccineType2: vaccinationDocs?.vaccination2ndDocs?.vaccineType,
        vaccineDate2: vaccinationDocs?.vaccination2ndDocs?.currentDate,
        nextVaccinationDate2: vaccinationDocs?.vaccination2ndDocs?.nextDate,
        vaccineStatus3: vaccinationDocs?.vaccinationBoosterDocs?.vaccinationStatus,
        vaccineType3: vaccinationDocs?.vaccinationBoosterDocs?.vaccineType,
        vaccineDate3: vaccinationDocs?.vaccinationBoosterDocs?.currentDate,
        nextVaccinationDate3: vaccinationDocs?.vaccinationBoosterDocs?.nextDate,
        aadharNo: aadhaarCardDocs?.aadhaarNo,
        aadhaarAddress: aadhaarCardDocs?.aadhaarAddress,
        panNo: panCardDocs?.panNumber,
        panDOB: panCardDocs?.dateOfBirth,
        passportNo: passportDoc?.passportNo,
        passportCountry: passportDoc?.country,
        passportIssueDate: passportDoc?.issueDate,
        passportExpiryDate: passportDoc?.expiryDate,
        visaNo: visaDocs?.visaNo,
        visaCountry: visaDocs?.country,
        visaType: visaDocs?.visaType,
        visaIssueDate: visaDocs?.issueDate,
        visaExpiryDate: visaDocs?.expiryDate,
        visaEntry: visaDocs?.entry,
        healthInsurance: healthInsuranceDocs?.registerationNo,
        healthInsuranceCompanyName: healthInsuranceDocs?.companyName,
        healthInsuranceIssueDate: healthInsuranceDocs?.issueDate,
        healthInsuranceExpiryDate: healthInsuranceDocs?.expiryDate,
        travelInsurance: travelInsuranceDocs?.registerationNo,
        travelInsuranceCountry: travelInsuranceDocs?.country,
        travelInsuranceCompanyName: travelInsuranceDocs?.travelInsuranceCompany,
        travelInsuranceIssueDate: travelInsuranceDocs?.issueDate,
        travelInsuranceExpiryDate: travelInsuranceDocs?.expiryDate,
        passportFrontLink: passportDoc?.passportFrontLink,
        passportBackLink: passportDoc?.passportBackLink,
        aadhaarFrontLink: aadhaarCardDocs?.aadhaarFrontLink,
        aadhaarBackLink: aadhaarCardDocs?.aadhaarBackLink,
        visaLink: visaDocs?.visaLink,
        travelInsuranceLink: travelInsuranceDocs?.travelInsuranceLink,
        healthInsuranceLink: healthInsuranceDocs?.healthInsuranceLink,
        vaccination1stDoseLink: vaccinationDocs?.vaccination1stDocs?.links,
        vaccination2ndDoseLink: vaccinationDocs?.vaccination2ndDocs?.links,
        vaccinationBoosterLink: vaccinationDocs?.vaccinationBoosterDocs?.links,
        panCardLink: panCardDocs?.panCardLink,
        additionalDoc1:
          indiviualUserData?.additionalDoc1 &&
          (indiviualUserData?.additionalDoc1 !== undefined ||
            indiviualUserData?.additionalDoc1 !== '')
            ? Object.keys(indiviualUserData?.additionalDoc1)?.length === 0
              ? []
              : [indiviualUserData?.additionalDoc1]
            : [],
        additionalDoc2:
          indiviualUserData?.additionalDoc2 &&
          (indiviualUserData?.additionalDoc2 !== undefined ||
            indiviualUserData?.additionalDoc2 !== '')
            ? Object.keys(indiviualUserData?.additionalDoc2)?.length === 0
              ? []
              : [indiviualUserData?.additionalDoc2]
            : []
      };

      setSendData(resData);
    } else {
      const resultData = {
        dob: '',
        gender: 'male',
        phone: '',
        name: '',
        relation: '',
        smokingStatus: '',
        mealpreference: '',
        freefield1: '',
        freefield2: '',
        freefield3: '',
        vaccineStatus1: '1st dose',
        vaccineType1: '',
        vaccineDate1: '',
        nextVaccinationDate1: '',
        vaccineStatus2: '2nd dose',
        vaccineType2: '',
        vaccineDate2: '',
        nextVaccinationDate2: '',
        vaccineStatus3: 'Booster',
        vaccineType3: '',
        vaccineDate3: '',
        nextVaccinationDate3: '',
        aadharNo: '',
        aadhaarAddress: '',
        panNo: '',
        panDOB: '',
        passportNo: '',
        passportCountry: '',
        passportIssueDate: '',
        passportExpiryDate: '',
        visaNo: '',
        visaCountry: '',
        visaType: '',
        visaIssueDate: '',
        visaExpiryDate: '',
        visaEntry: '',
        healthInsurance: '',
        healthInsuranceCompanyName: '',
        healthInsuranceIssueDate: '',
        healthInsuranceExpiryDate: '',
        travelInsurance: '',
        travelInsuranceCountry: '',
        travelInsuranceCompanyName: '',
        travelInsuranceIssueDate: '',
        travelInsuranceExpiryDate: '',
        passportFrontLink: [],
        passportBackLink: [],
        aadhaarFrontLink: [],
        aadhaarBackLink: [],
        visaLink: [],
        travelInsuranceLink: [],
        healthInsuranceLink: [],
        vaccination1stDoseLink: [],
        vaccination2ndDoseLink: [],
        vaccinationBoosterLink: [],
        panCardLink: [],
        additionalDoc1: [],
        additionalDoc2: []
      };
      setSendData(resultData);
    }
  }, [indiviualUserData, isEdit]);

  // form onchanges function

  const handleStateChange = (key, value) => {
    if (key === 'name') {
      if (nameValidate(value) === false) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Special charaters are not allowed'
        });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'phone') {
      if (value < 0) {
        setSendData({ ...sendData, phone: '' });
      } else if (value.length <= 10) {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else if (key === 'dob') {
      if (value > moment(new Date()).format('YYYY-MM-DD')) {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'error',
          isOpen: true,
          label: 'Please select Valid date'
        });
        setSendData({ ...sendData, dateOfBirth: moment(new Date()).format('YYYY-MM-DD') });
      } else {
        setSendData((prevState) => {
          prevState[`${key}`] = value;
          return { ...prevState };
        });
      }
    } else {
      setSendData((prevState) => {
        prevState[`${key}`] = value;
        return { ...prevState };
      });
    }
  };

  // form onsubmit function (add and edit)

  function handleSubmit(event) {
    event?.preventDefault();

    if (step === 1) {
      const postData = {
        fullName: sendData?.name,
        mobileNumber: sendData?.phone,
        dateOfBirth: sendData?.dob,
        gender: sendData?.gender,
        relation: sendData?.relation,
        // smokingStatus: sendData?.smokingStatus,
        mealpreference: sendData?.mealpreference,
        freefield1: sendData?.freefield1,
        freefield2: sendData?.freefield2,
        freefield3: sendData?.freefield3
      };

      if (isEdit === 'edit') {
        updateId &&
          API_PATCH(`user/updateUser?userId=${updateId}&ownerId=${dataId}`, postData)
            .then((res) => {
              dispatch({
                type: 'ALERT_OPEN',
                severity: 'success',
                isOpen: true,
                label: res.data?.message ?? 'Success'
              });
              setStep(2);
              getUserInfo();
            })
            .catch((err) => {
              API_HANDLE_ERROR(err, dispatch);
            });
      } else {
        API_POST(`user/addUser?addMode=dependent&userId=${dataId}`, postData)
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            setUpdateId(res?.data?._id);
            setStep(2);
            getUserInfo();
            addEditDepedentStatus();
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
      }
    } else if (step === 2) {
      const resultPassport = [...sendData?.passportFrontLink, ...sendData?.passportBackLink].filter(
        (item) => {
          return item !== null && item !== undefined;
        }
      );
      const resultAadhar = [...sendData?.aadhaarFrontLink, ...sendData?.aadhaarBackLink].filter(
        (item) => {
          return item !== null && item !== undefined;
        }
      );
      const postData = {
        passportDocs: [
          {
            passportNo: resultPassport?.length !== 0 && !resultPassport ? sendData?.passportNo : '',
            country:
              resultPassport?.length !== 0 && !resultPassport ? sendData?.passportCountry : '',
            // issueDate:
            //   resultPassport?.length !== 0 && !resultPassport ? sendData?.passportIssueDate : null,
            expiryDate:
              resultPassport?.length !== 0 && !resultPassport ? sendData?.passportExpiryDate : null,
            links: resultPassport
          }
        ],
        aadhaarCardDocs: [
          {
            aadhaarNo: resultAadhar?.length !== 0 && !resultAadhar ? sendData?.aadharNo : '',
            dateOfBirth: resultAadhar?.length !== 0 && !resultAadhar ? sendData?.dob : '',
            gender: resultAadhar?.length !== 0 && !resultAadhar ? sendData?.gender : '',
            links: resultAadhar
          }
        ],
        visaDocs: [
          {
            visaNo: sendData?.visaLink?.length !== 0 ? sendData?.visaNo : '',
            country: sendData?.visaLink?.length !== 0 ? sendData?.visaCountry : '',
            visaType: sendData?.visaLink?.length !== 0 ? sendData?.visaType : '',
            // issueDate: sendData?.visaLink?.length !== 0 ? sendData?.visaIssueDate : null,
            expiryDate: sendData?.visaLink?.length !== 0 ? sendData?.visaExpiryDate : null,
            entry: sendData?.visaLink?.length !== 0 ? sendData?.visaEntry : '',
            links: sendData?.visaLink
          }
        ],
        travelInsuranceDocs: [
          {
            registerationNo:
              sendData?.travelInsuranceLink?.length !== 0 ? sendData?.travelInsurance : '',
            country:
              sendData?.travelInsuranceLink?.length !== 0 ? sendData?.travelInsuranceCountry : '',
            companyName:
              sendData?.travelInsuranceLink?.length !== 0 ? sendData?.travelInsuranceCompany : '',
            // issueDate:
            //   sendData?.travelInsuranceLink?.length !== 0
            //     ? sendData?.travelInsuranceIssueDate
            //     : null,
            expiryDate:
              sendData?.travelInsuranceLink?.length !== 0
                ? sendData?.travelInsuranceExpiryDate
                : null,
            links: sendData?.travelInsuranceLink
          }
        ],
        healthInsuranceDocs: [
          {
            registerationNo:
              sendData?.healthInsuranceLink?.length !== 0 ? sendData?.healthInsurance : '',
            companyName:
              sendData?.healthInsuranceLink?.length !== 0
                ? sendData?.healthInsuranceCompanyName
                : '',
            // issueDate:
            //   sendData?.healthInsuranceLink?.length !== 0
            //     ? sendData?.healthInsuranceIssueDate
            //     : null,
            expiryDate:
              sendData?.healthInsuranceLink?.length !== 0
                ? sendData?.healthInsuranceExpiryDate
                : null,
            links: sendData?.healthInsuranceLink
          }
        ],
        panCardDocs: [
          {
            panNumber: sendData?.panCardLink?.length !== 0 ? sendData?.aadharNo : '',
            dateOfBirth: sendData?.panCardLink?.length !== 0 ? sendData?.panDOB : null,
            links: sendData?.panCardLink
          }
        ],
        vaccinationDocs: [
          {
            vaccinationStatus: '1st dose',
            vaccineType:
              sendData?.vaccination1stDoseLink?.length !== 0 ? sendData?.vaccineType1 : '',
            // nextDate:
            //   sendData?.vaccination1stDoseLink?.length !== 0
            //     ? sendData?.nextVaccinationDate1
            //     : null,
            currentDate:
              sendData?.vaccination1stDoseLink?.length !== 0 ? sendData?.vaccineDate1 : null,
            links: sendData?.vaccination1stDoseLink
          },
          {
            vaccinationStatus: '2nd dose',
            vaccineType:
              sendData?.vaccination2ndDoseLink?.length !== 0 ? sendData?.vaccineType2 : '',
            // nextDate:
            //   sendData?.vaccination2ndDoseLink?.length !== 0
            //     ? sendData?.nextVaccinationDate2
            //     : null,
            currentDate:
              sendData?.vaccination2ndDoseLink?.length !== 0 ? sendData?.vaccineDate2 : null,
            links: sendData?.vaccination2ndDoseLink
          },
          {
            vaccinationStatus: 'Booster',
            vaccineType:
              sendData?.vaccinationBoosterLink?.length !== 0 ? sendData?.vaccineType3 : '',
            // nextDate:
            //   sendData?.vaccinationBoosterLink?.length !== 0
            //     ? sendData?.nextVaccinationDate3
            //     : null,
            currentDate:
              sendData?.vaccinationBoosterLink?.length !== 0 ? sendData?.vaccineDate3 : null,
            links: sendData?.vaccinationBoosterLink
          }
        ],
        additionalDoc1: sendData?.additionalDoc1?.length > 0 ? sendData?.additionalDoc1[0] : {},
        additionalDoc2: sendData?.additionalDoc2?.length > 0 ? sendData?.additionalDoc2[0] : {}
      };

      // if (resultAadhar?.length === 0) {
      //   dispatch({
      //     type: 'ALERT_OPEN',
      //     severity: 'error',
      //     isOpen: true,
      //     label: 'Please Upload Aadhaar Card',
      //     time: 3000
      //   });
      // } else {
      if (newUploadImage) {
        setIsLoading(true);
        const resultData = {
          passportUrl: newUploadImage.passportUrl,
          aadhaarCardUrl: newUploadImage.aadhaarCardUrl,
          panCardUrl: newUploadImage.panCardUrl,
          visaUrl: newUploadImage.visaUrl,
          vaccineUrls: {
            firstDoseUrl: newUploadImage.firstVaccineUrl,
            secondDoseUrl: newUploadImage.secondVaccineUrl,
            thirdDoseUrl: newUploadImage.thirdVaccineUrl
          }
        };

        API_POST('ocr/getDocumentDetails', resultData)
          .then((res) => {
            const resData = res?.data?.documentData;
            const postNewData = {
              panNo: resData?.panCardData?.number || '',
              panDOB: resData?.panCardData?.dateOfBirth || null,
              aadharNo: resData?.aadhaarCardData?.number || '',
              passportNo: resData?.passportData?.number || '',
              passportExpiryDate: resData?.passportData?.expiryDate || null,
              vaccineType1: resData?.vaccineDetails?.firstDose?.vaccineName || '',
              vaccineType2: resData?.vaccineDetails?.secondDose?.vaccineName || '',
              vaccineType3: resData?.vaccineDetails?.thirdDose?.vaccineName || '',
              vaccineDate1: resData?.vaccineDetails?.firstDose?.vaccinationDate || null,
              vaccineDate2: resData?.vaccineDetails?.secondDose?.vaccinationDate || null,
              vaccineDate3: resData?.vaccineDetails?.thirdDose?.vaccinationDate || null,
              visaExpiryDate: resData?.visaData?.expiryDate || null
            };
            setSendData({
              ...sendData,
              panNo: resData?.panCardData?.number || sendData?.panNo,
              panDOB: resData?.panCardData?.dateOfBirth || sendData?.panDOB,
              aadharNo: resData?.aadhaarCardData?.number || sendData?.aadharNo,
              passportNo: resData?.passportData?.number || sendData?.passportNo,
              passportExpiryDate: resData?.passportData?.expiryDate || sendData?.passportExpiryDate,
              vaccineType1:
                resData?.vaccineDetails?.firstDose?.vaccineName || sendData?.vaccineType1,
              vaccineType2:
                resData?.vaccineDetails?.secondDose?.vaccineName || sendData?.vaccineType2,
              vaccineType3:
                resData?.vaccineDetails?.thirdDose?.vaccineName || sendData?.vaccineType3,
              vaccineDate1:
                resData?.vaccineDetails?.firstDose?.vaccinationDate || sendData?.vaccineDate1,
              vaccineDate2:
                resData?.vaccineDetails?.secondDose?.vaccinationDate || sendData?.vaccineDate2,
              vaccineDate3:
                resData?.vaccineDetails?.thirdDose?.vaccinationDate || sendData?.vaccineDate3,
              visaExpiryDate: resData?.visaData?.expiryDate || sendData?.visaExpiryDate
            });
            setStep(3);
            setNewUploadImage('');
            secondUpdate(postData, postNewData);
            setIsLoading(false);
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
            setIsLoading(false);
          });
      } else {
        updateId &&
          API_PATCH(
            `user/updateUser?userId=${updateId}&updateMode=document&ownerId=${dataId}`,
            postData
          )
            .then((res) => {
              dispatch({
                type: 'ALERT_OPEN',
                severity: 'success',
                isOpen: true,
                label: res.data?.message ?? 'Success'
              });
              newUploadImage || setStep(3);
            })
            .catch((err) => {
              API_HANDLE_ERROR(err, dispatch);
            });
      }
      // }
    } else {
      const resultPassport = [...sendData?.passportFrontLink, ...sendData?.passportBackLink].filter(
        (item) => {
          return item !== null && item !== undefined;
        }
      );
      const resultAadhar = [...sendData?.aadhaarFrontLink, ...sendData?.aadhaarBackLink].filter(
        (item) => {
          return item !== null && item !== undefined;
        }
      );
      const postData = {
        passportDocs: [
          {
            passportNo: sendData?.passportNo,
            country: sendData?.passportCountry,
            // issueDate: sendData?.passportIssueDate,
            expiryDate: sendData?.passportExpiryDate,
            links: resultPassport
          }
        ],
        aadhaarCardDocs: [
          {
            aadhaarNo: sendData?.aadharNo,
            dateOfBirth: sendData?.dob,
            gender: sendData?.gender,
            links: resultAadhar
          }
        ],
        visaDocs: [
          {
            visaNo: sendData?.visaNo,
            country: sendData?.visaCountry,
            visaType: sendData?.visaType,
            // issueDate: sendData?.visaIssueDate,
            expiryDate: sendData?.visaExpiryDate,
            entry: sendData?.visaEntry,
            links: sendData?.visaLink
          }
        ],
        travelInsuranceDocs: [
          {
            registerationNo: sendData?.travelInsurance,
            country: sendData?.travelInsuranceCountry,
            companyName: sendData?.travelInsuranceCompany,
            // issueDate: sendData?.travelInsuranceIssueDate,
            expiryDate: sendData?.travelInsuranceExpiryDate,
            links: sendData?.travelInsuranceLink
          }
        ],
        healthInsuranceDocs: [
          {
            registerationNo: sendData?.healthInsurance,
            companyName: sendData?.healthInsuranceCompanyName,
            // issueDate: sendData?.healthInsuranceIssueDate,
            expiryDate: sendData?.healthInsuranceExpiryDate,
            links: sendData?.healthInsuranceLink
          }
        ],
        panCardDocs: [
          {
            panNumber: sendData?.panNo,
            dateOfBirth: sendData?.panDOB,
            links: sendData?.panCardLink
          }
        ],
        vaccinationDocs: [
          {
            vaccinationStatus: '1st dose',
            vaccineType: sendData?.vaccineType1,
            // nextDate: sendData?.nextVaccinationDate1,
            currentDate: sendData?.vaccineDate1,
            links: sendData?.vaccination1stDoseLink
          },
          {
            vaccinationStatus: '2nd dose',
            vaccineType: sendData?.vaccineType2,
            // nextDate: sendData?.nextVaccinationDate2,
            currentDate: sendData?.vaccineDate2,
            links: sendData?.vaccination2ndDoseLink
          },
          {
            vaccinationStatus: 'Booster',
            vaccineType: sendData?.vaccineType3,
            // nextDate: sendData?.nextVaccinationDate3,
            currentDate: sendData?.vaccineDate3,
            links: sendData?.vaccinationBoosterLink
          }
        ],
        currentVaccinationStatus:
          sendData?.vaccinationBoosterLink && sendData?.vaccinationBoosterLink?.length !== 0
            ? 'Booster'
            : sendData?.vaccination2ndDoseLink && sendData?.vaccination2ndDoseLink?.length !== 0
            ? '2nd dose'
            : sendData?.vaccination1stDoseLink && sendData?.vaccination1stDoseLink?.length !== 0
            ? '1st dose'
            : null,

        lastestVacinationDate:
          sendData?.vaccinationBoosterLink?.length === 0
            ? sendData?.vaccination2ndDoseLink?.length === 0
              ? sendData?.vaccineDate1
              : sendData?.vaccineDate2
            : sendData?.vaccineDate3,
        additionalDoc1: sendData?.additionalDoc1?.length > 0 ? sendData?.additionalDoc1[0] : {},
        additionalDoc2: sendData?.additionalDoc2?.length > 0 ? sendData?.additionalDoc2[0] : {}
      };
      updateId &&
        API_PATCH(
          `user/updateUser?userId=${updateId}&updateMode=document&ownerId=${dataId}`,
          postData
        )
          .then((res) => {
            dispatch({
              type: 'ALERT_OPEN',
              severity: 'success',
              isOpen: true,
              label: res.data?.message ?? 'Success'
            });
            setFromSubmit(true);
            setStep(4);
            handleStep(4, data, data?._id);
          })
          .catch((err) => {
            API_HANDLE_ERROR(err, dispatch);
          });
    }
  }

  const secondUpdate = (postData, postNewData) => {
    const resPassport = [...sendData?.passportFrontLink, ...sendData?.passportBackLink].filter(
      (item) => {
        return item !== null && item !== undefined;
      }
    );
    const resAadhar = [...sendData?.aadhaarFrontLink, ...sendData?.aadhaarBackLink].filter(
      (item) => {
        return item !== null && item !== undefined;
      }
    );

    const res = {
      ...postData,
      passportDocs: [
        {
          passportNo: postNewData?.passportNo || sendData?.passportNo,
          country: sendData?.passportCountry,
          // issueDate: sendData?.passportIssueDate,
          expiryDate: postNewData?.passportExpiryDate || sendData?.passportExpiryDate,
          links: resPassport
        }
      ],
      aadhaarCardDocs: [
        {
          aadhaarNo: postNewData?.aadharNo || sendData?.aadharNo,
          dateOfBirth: resAadhar?.length === 0 || !resAadhar ? null : sendData?.dob,
          gender: sendData?.gender,
          links: resAadhar
        }
      ],

      panCardDocs: [
        {
          panNumber: postNewData?.panNo || sendData?.panNo,
          dateOfBirth: postNewData?.panDOB || sendData?.panDOB,
          links: sendData?.panCardLink
        }
      ],
      vaccinationDocs: [
        {
          vaccinationStatus: '1st dose',
          vaccineType: postNewData?.vaccineType1 || sendData?.vaccineType1,
          // nextDate: sendData?.nextVaccinationDate1,
          currentDate: postNewData?.vaccineDate1 || sendData?.vaccineDate1,
          links: sendData?.vaccination1stDoseLink
        },
        {
          vaccinationStatus: '2nd dose',
          vaccineType: postNewData?.vaccineType2 || sendData?.vaccineType2,
          // nextDate: sendData?.nextVaccinationDate2,
          currentDate: postNewData?.vaccineDate2 || sendData?.vaccineDate2,
          links: sendData?.vaccination2ndDoseLink
        },
        {
          vaccinationStatus: 'Booster',
          vaccineType: postNewData?.vaccineType3 || sendData?.vaccineType3,
          // nextDate: sendData?.nextVaccinationDate3,
          currentDate: postNewData?.vaccineDate3 || sendData?.vaccineDate3,
          links: sendData?.vaccinationBoosterLink
        }
      ],
      visaDocs: [
        {
          visaNo: sendData?.visaNo,
          country: sendData?.visaCountry,
          visaType: sendData?.visaType,
          // issueDate: sendData?.visaLink?.length !== 0 ? sendData?.visaIssueDate : null,
          expiryDate: postNewData?.visaExpiryDate || sendData?.visaExpiryDate,
          entry: sendData?.visaEntry,
          links: sendData?.visaLink
        }
      ]
    };

    updateId &&
      API_PATCH(`user/updateUser?userId=${updateId}&updateMode=document&ownerId=${dataId}`, res)
        .then((res) => {
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: res.data?.message ?? 'Success'
          });
          newUploadImage || setStep(3);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  // upload documents flow
  const hiddenPassportFrontInput = useRef(null);
  const hiddenPassportBackInput = useRef(null);
  const hiddenAadhaarFrontInput = useRef(null);
  const hiddenAadhaarBackInput = useRef(null);
  // const hiddenVisaInput = useRef(null);
  const hiddenPanCardInput = useRef(null);
  // const hiddenTravalInsuranceInput = useRef(null);
  // const hiddenHealthInsuranceInput = useRef(null);
  // const hiddenVaccination1stDoseInput = useRef(null);
  // const hiddenVaccination2ndDoseInput = useRef(null);
  // const hiddenVaccinationBoosterInput = useRef(null);
  // const hiddenAdditionDocs1Input = useRef(null);
  // const hiddenAdditionDocs2Input = useRef(null);

  const handlePassportFront = (event) => {
    hiddenPassportFrontInput.current.click();
  };
  const handlePassportBack = (event) => {
    hiddenPassportBackInput.current.click();
  };
  const handleAadhaarFront = (event) => {
    hiddenAadhaarFrontInput.current.click();
  };
  const handleAadhaarBack = (event) => {
    hiddenAadhaarBackInput.current.click();
  };
  // const handleVisa = (event) => {
  //   hiddenVisaInput.current.click();
  // };
  // const handleTravalInsurance = (event) => {
  //   hiddenTravalInsuranceInput.current.click();
  // };
  // const handleHealthInsurance = (event) => {
  //   hiddenHealthInsuranceInput.current.click();
  // };
  const handlePanCard = (event) => {
    hiddenPanCardInput.current.click();
  };
  // const handleVaccination1stDose = (event) => {
  //   hiddenVaccination1stDoseInput.current.click();
  // };
  // const handleVaccination2ndDose = (event) => {
  //   hiddenVaccination2ndDoseInput.current.click();
  // };
  // const handleBooster = (event) => {
  //   hiddenVaccinationBoosterInput.current.click();
  // };

  // const handleAdditionDocs1 = (event) => {
  //   hiddenAdditionDocs1Input.current.click();
  // };
  // const handleAdditionDocs2 = (event) => {
  //   hiddenAdditionDocs2Input.current.click();
  // };

  const handleimage = async (status, event, type) => {
    const arrayImage = [];
    const img = event.target.files;
    setIsDocUpload(true);
    for (let i = 0; i < img.length; i++) {
      const docsType = img[i]?.type?.split('/');
      const name = img[i]?.name;
      const url = await precenturl(docsType[1], name);
      const file = img[i];

      const config = {
        method: 'put',
        url: url.signedUrl,
        headers: {
          'x-ms-blob-content-type': img[i].type,
          'content-type': 'application/octet-stream',
          'x-ms-blob-type': 'BlockBlob'
        },
        data: file
      };
      axios(config)
        .then(function (response) {
          const media = url.mediaUrl?.split('.');

          const obj = {
            uri: url.mediaUrl,
            mediaType: media[media.length - 1],
            type: type,
            fileName: name
          };

          arrayImage.push(obj);

          if (status === 'passportFront') {
            setNewUploadImage({ ...newUploadImage, passportUrl: url.mediaUrl });
            // OCRDocs.passportUrl = ;
          } else if (status === 'aadharFront') {
            setNewUploadImage({ ...newUploadImage, aadhaarCardUrl: url.mediaUrl });
            // OCRDocs.aadhaarCardUrl = url.mediaUrl;
          } else if (status === 'pancard') {
            setNewUploadImage({ ...newUploadImage, panCardUrl: url.mediaUrl });
            // OCRDocs.panCardUrl = url.mediaUrl;
          } else if (status === 'vaccination1stDose') {
            setNewUploadImage({ ...newUploadImage, firstVaccineUrl: url.mediaUrl });
            // OCRDocs.firstVaccineUrl = url.mediaUrl;
          } else if (status === 'vaccination2ndDose') {
            setNewUploadImage({ ...newUploadImage, secondVaccineUrl: url.mediaUrl });
            // OCRDocs.secondVaccineUrl = url.mediaUrl;
          } else if (status === 'vaccinationBooster') {
            setNewUploadImage({ ...newUploadImage, thirdVaccineUrl: url.mediaUrl });
            // OCRDocs.thirdVaccineUrl = url.mediaUrl;
          } else if (status === 'visa') {
            setNewUploadImage({ ...newUploadImage, visaUrl: url.mediaUrl });
          }
          setIsDocUpload(false);
          dispatch({
            type: 'ALERT_OPEN',
            severity: 'success',
            isOpen: true,
            label: response?.data?.message || 'upload Successfully'
          });
        })
        .catch(function (error) {
          API_HANDLE_ERROR(error, dispatch);
          setIsDocUpload(false);
        });
    }
    event.target.value = '';
    switch (true) {
      case status === 'passportFront':
        handleStateChange('passportFrontLink', arrayImage);
        break;
      case status === 'passportBack':
        handleStateChange('passportBackLink', arrayImage);
        break;
      case status === 'aadharFront':
        handleStateChange('aadhaarFrontLink', arrayImage);
        // OCRDocs.aadhaarCardUrl = arrayImage[0]?.uri;
        break;
      case status === 'aadharBack':
        handleStateChange('aadhaarBackLink', arrayImage);
        break;
      case status === 'visa':
        handleStateChange('visaLink', arrayImage);
        break;
      case status === 'travalInsurance':
        handleStateChange('travelInsuranceLink', arrayImage);
        break;
      case status === 'healthInsurance':
        handleStateChange('healthInsuranceLink', arrayImage);
        break;
      case status === 'vaccination1stDose':
        handleStateChange('vaccination1stDoseLink', arrayImage);
        break;
      case status === 'vaccination2ndDose':
        handleStateChange('vaccination2ndDoseLink', arrayImage);
        break;
      case status === 'vaccinationBooster':
        handleStateChange('vaccinationBoosterLink', arrayImage);
        break;
      case status === 'pancard':
        handleStateChange('panCardLink', arrayImage);
        // OCRDocs.panCardUrl = arrayImage[0]?.uri;
        break;
      case status === 'additionalDoc1':
        handleStateChange('additionalDoc1', arrayImage);
        // OCRDocs.panCardUrl = arrayImage[0]?.uri;
        break;
      case status === 'additionalDoc2':
        handleStateChange('additionalDoc2', arrayImage);
        // OCRDocs.panCardUrl = arrayImage[0]?.uri;
        break;
      default:
        return null;
    }
    // setNewUploadImage(OCRDocs);
  };
  const precenturl = async (type, name) => {
    try {
      const data = await PRE_SEND_URL('user/incident/preSignedMediaUrl', {
        fileType: type,
        fileName: name.split('.')[0],
        featureType: 'userDocs'
      });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  // user form

  function yourDetailsForm() {
    return (
      <Item sx={{ mt: 2, maxHeight: '65vh', overflow: 'auto' }}>
        <Item
          sx={{
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            gap: '10px'
          }}
          md={12}
          xs={12}
          lg={12}>
          <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
            <TextField
              variant="outlined"
              name="name"
              label="Name"
              required={true}
              value={sendData.name}
              type="text"
              fullWidth
              onChange={(e) => handleStateChange('name', e.target.value)}
            />
          </Item>
          <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Relation</InputLabel>
              <Select
                name="relation"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sendData.relation}
                onChange={(e) => handleStateChange('relation', e.target.value)}
                label="Relation">
                {relationArray?.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Item>

          <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
            <TextField
              variant="outlined"
              name="phone"
              label="Phone no"
              // required={true}
              type="number"
              fullWidth
              onBlur={(e) => {
                if (e.target.value !== '' && mobileNumberValidate(sendData?.phone) === false) {
                  dispatch({
                    type: 'ALERT_OPEN',
                    severity: 'error',
                    isOpen: true,
                    label: 'Please enter correct phone number'
                  });
                }
              }}
              value={sendData.phone}
              onChange={(e) => handleStateChange('phone', e.target.value)}
            />
          </Item>
          <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
            <TextField
              variant="outlined"
              name="dob"
              value={getDocsDate(sendData.dob)}
              // required={true}
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              label="DOB"
              onChange={(e) => handleStateChange('dob', e.target.value)}
            />
          </Item>

          <Item mt={1.4} md={5.8} xs={12} lg={3.6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Meal preference</InputLabel>
              <Select
                name="branch"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sendData.mealpreference}
                onChange={(e) => handleStateChange('mealpreference', e.target.value)}
                label="Meal preference">
                {MealPreArray?.map((item, index) => {
                  return (
                    <MenuItem value={item?.value} key={index}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Item>

          {/* <Item mt={1.4} md={5.8} xs={12} lg={3.6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Smoking Status</InputLabel>
              <Select
                name="branch"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sendData?.smokingStatus}
                onChange={(e) => handleStateChange('smokingStatus', e.target.value)}
                label="Smoking Status">
                {SomkingArray?.map((item, index) => {
                  return (
                    <MenuItem value={item?.value} key={index}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
              </Item> */}
          <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
            <TextField
              variant="outlined"
              name="freefield1"
              label="Free Filed 1"
              value={sendData.freefield1}
              onChange={(e) => handleStateChange('freefield1', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
          <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
            <TextField
              variant="outlined"
              name="freefield2"
              label="Free Field 2"
              value={sendData.freefield2}
              onChange={(e) => handleStateChange('freefield2', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
          <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
            <TextField
              variant="outlined"
              name="freefield3"
              label="Free Field 3"
              value={sendData.freefield3}
              onChange={(e) => handleStateChange('freefield3', e.target.value)}
              type="text"
              fullWidth
            />
          </Item>
          <Item sx={{ mt: 2 }} md={5.8} xs={12} lg={3.6}>
            <Typography>Gender</Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="gender"
                value={sendData.gender}
                required={true}
                onChange={(e) => handleStateChange('gender', e.target.value)}>
                <FormControlLabel value={'male'} control={<Radio />} label="Male" />
                <FormControlLabel value={'female'} control={<Radio />} label="Female" />
                <FormControlLabel value={'others'} control={<Radio />} label="Others" />
              </RadioGroup>
            </FormControl>
          </Item>
        </Item>
      </Item>
    );
  }

  // user docs

  const docsUi = (status, data, linkType) => {
    const resData = data && data[0];

    return (
      <Item md={11} mt={1}>
        <Typography sx={{ fontSize: '14px', fontWeight: '500', textAlign: 'left' }} mb={0.2}>
          {status}
        </Typography>
        <Item
          sx={{
            height: '60px',
            border: '0.90998px solid #E5E5E5',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1.5,
            borderRadius: '7px'
          }}>
          <Item sx={{ height: '45px', width: '60px' }}>
            {resData?.mediaType === 'pdf' ? (
              <img
                src="https://png.pngitem.com/pimgs/s/127-1279117_transparent-pdf-icon-hd-png-download.png"
                alt="docs"
                width="45px"
                height="45px"
              />
            ) : (
              <img
                src={
                  resData?.uri ||
                  'https://images.wondershare.com/repairit/aticle/2021/07/resolve-images-not-showing-problem-1.jpg'
                }
                alt="docs"
                width="60px"
                height="45px"
                style={{ border: '1px solid grey' }}
              />
            )}
          </Item>
          <Item>
            <Typography sx={{ fontSize: '13px' }}>{resData?.fileName}</Typography>
          </Item>
          <Item>
            <CancelIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleDeleteImg(resData, linkType)}
            />
          </Item>
        </Item>
      </Item>
    );
  };

  const handleDeleteImg = (imgUrl, linkType) => {
    API_DELETE(`travelPackage/deleteBlob`, { url: imgUrl?.uri })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res?.message ?? 'Success'
        });
        switch (linkType) {
          case 'passportFront':
            setSendData({
              ...sendData,
              passportNo: '',
              passportCountry: '',
              passportIssueDate: null,
              passportExpiryDate: null,
              passportFrontLink: []
            });
            break;
          case 'passportBack':
            setSendData({
              ...sendData,
              passportBackLink: [],
              passportNo: '',
              passportCountry: '',
              passportIssueDate: null,
              passportExpiryDate: null
            });
            break;
          case 'aadhaarFront':
            setSendData({ ...sendData, aadhaarFrontLink: [], aadharNo: '', aadhaarAddress: '' });
            break;
          case 'aadhaarBack':
            setSendData({ ...sendData, aadhaarBackLink: [], aadharNo: '', aadhaarAddress: '' });
            break;
          case 'additionalDoc1':
            setSendData({ ...sendData, additionalDoc1: [] });
            break;
          case 'additionalDoc2':
            setSendData({ ...sendData, additionalDoc2: [] });
            break;
          case 'visa':
            setSendData({
              ...sendData,
              visaLink: [],
              visaNo: '',
              visaCountry: '',
              visaType: '',
              visaIssueDate: null,
              visaExpiryDate: null,
              visaEntry: ''
            });
            break;
          case 'travelInsurance':
            setSendData({
              ...sendData,
              travelInsuranceLink: [],
              travelInsurance: '',
              travelInsuranceCountry: '',
              travelInsuranceCompanyName: '',
              travelInsuranceIssueDate: null,
              travelInsuranceExpiryDate: null
            });
            break;
          case 'healthInsurance':
            setSendData({
              ...sendData,
              healthInsuranceLink: [],
              healthInsurance: '',
              healthInsuranceCompanyName: '',
              healthInsuranceIssueDate: null,
              healthInsuranceExpiryDate: null
            });
            break;
          case 'panCard':
            setSendData({ ...sendData, panCardLink: [], panNo: '', panDOB: null });
            break;
          case '1stDose':
            setSendData({
              ...sendData,
              vaccination1stDoseLink: [],
              vaccineType1: '',
              vaccineDate1: null,
              nextVaccinationDate1: null
            });
            break;
          case '2ndDose':
            setSendData({
              ...sendData,
              vaccination2ndDoseLink: [],
              vaccineType2: '',
              vaccineDate2: null,
              nextVaccinationDate2: null
            });
            break;
          case 'boosterDose':
            setSendData({
              ...sendData,
              vaccinationBoosterLink: [],
              vaccineType3: '',
              vaccineDate3: null,
              nextVaccinationDate3: null
            });
            break;
          default:
            return null;
        }
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  function documents() {
    return (
      <React.Fragment>
        <Typography sx={{ textAlign: 'center', fontSize: '1rem' }}>
          Upload your Documents
        </Typography>
        <Container
          mt={2}
          align="center"
          sx={{
            opacity: isDocUpload ? 0.2 : 1,
            zIndex: isDocUpload ? 0 : 1,
            position: 'relative',
            maxHeight: !scrrenSizeSmall ? '65vh' : '80vh',
            overflow: 'auto'
          }}>
          {isDocUpload && (
            <Item
              sx={{
                ml: '45%',
                mt: '30%',
                zIndex: 2,
                position: 'absolute',
                opacity: 2
              }}>
              <CircularProgress size={80} style={{ color: 'green', zIndex: 2 }} />
            </Item>
          )}

          <Item
            md={12}
            xs={12}
            lg={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
              gap: '20px'
            }}>
            <Item mt={2} md={12} xs={12} lg={5.8}>
              <Item className={classes.docContainer}>
                <Typography>Aadhaar card</Typography>
                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={handleAadhaarFront}>
                  Upload(Front)
                </Button>
                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={handleAadhaarBack}>
                  Upload(Back)
                </Button>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenAadhaarFrontInput}
                  multiple
                  onChange={(e) => handleimage('aadharFront', e, 'front')}
                  style={{ display: 'none' }}
                />
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenAadhaarBackInput}
                  multiple
                  onChange={(e) => handleimage('aadharBack', e, 'back')}
                  style={{ display: 'none' }}
                />
              </Item>
              <Item mt={1} className={classes.uploadDocContainer}>
                {sendData?.aadhaarFrontLink !== undefined &&
                sendData?.aadhaarFrontLink.length >= 1 &&
                sendData?.aadhaarFrontLink[0] !== undefined &&
                sendData?.aadhaarFrontLink[0] !== null
                  ? docsUi('Front', sendData?.aadhaarFrontLink, 'aadhaarFront')
                  : ''}
                {sendData?.aadhaarBackLink !== undefined &&
                sendData?.aadhaarBackLink.length >= 1 &&
                sendData?.aadhaarBackLink[0] !== undefined &&
                sendData?.aadhaarBackLink[0] !== null
                  ? docsUi('Back', sendData?.aadhaarBackLink, 'aadhaarBack')
                  : ''}
              </Item>
              <Typography
                className={classes.sampleFileText}
                sx={{ fontSize: '12px' }}
                onClick={() => handleSampleDocsModal('aadhaar')}>
                Click here to view the sample file.
              </Typography>
            </Item>
            <Item mt={2} md={12} xs={12} lg={5.8}>
              <Item className={classes.docContainer}>
                <Typography>PAN Card</Typography>
                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={handlePanCard}>
                  Upload
                </Button>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenPanCardInput}
                  multiple
                  onChange={(e) => handleimage('pancard', e, 'front')}
                  style={{ display: 'none' }}
                />
              </Item>
              <Item mt={1} className={classes.uploadDocContainer}>
                {sendData?.panCardLink !== undefined &&
                sendData?.panCardLink.length >= 1 &&
                sendData?.panCardLink[0] !== undefined
                  ? docsUi('', sendData?.panCardLink, 'panCard')
                  : ''}
              </Item>
              <Typography
                className={classes.sampleFileText}
                sx={{ fontSize: '12px' }}
                onClick={() => handleSampleDocsModal('pancard')}>
                Click here to view the sample file.
              </Typography>
            </Item>

            <Item mt={2} md={12} xs={12} lg={5.8}>
              <Item className={classes.docContainer}>
                <Typography>Passport</Typography>
                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={handlePassportFront}>
                  Upload(Front)
                </Button>
                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={handlePassportBack}>
                  Upload(Back)
                </Button>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenPassportFrontInput}
                  multiple
                  onChange={(e) => handleimage('passportFront', e, 'front')}
                  style={{ display: 'none' }}
                />
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenPassportBackInput}
                  multiple
                  onChange={(e) => handleimage('passportBack', e, 'back')}
                  style={{ display: 'none' }}
                />
              </Item>

              <Item mt={1} className={classes.uploadDocContainer} md={12} xs={12}>
                {sendData?.passportFrontLink !== undefined &&
                sendData?.passportFrontLink.length >= 1 &&
                sendData?.passportFrontLink[0] !== undefined &&
                sendData?.passportFrontLink[0] !== null
                  ? docsUi('Front', sendData?.passportFrontLink, 'passportFront')
                  : ''}
                {sendData?.passportBackLink !== undefined &&
                sendData?.passportBackLink.length >= 1 &&
                sendData?.passportBackLink[0] !== undefined &&
                sendData?.passportBackLink[0] !== null
                  ? docsUi('Back', sendData?.passportBackLink, 'passportBack')
                  : ''}
              </Item>
              <Typography
                className={classes.sampleFileText}
                sx={{ fontSize: '12px' }}
                onClick={() => handleSampleDocsModal('passport')}>
                Click here to view the sample file.
              </Typography>
            </Item>
            {/* <Item mt={2} md={12} xs={12} lg={5.8}>
              <Item className={classes.docContainer}>
                <Typography>Photo</Typography>
                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={handleAdditionDocs2}>
                  Upload
                </Button>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenAdditionDocs2Input}
                  multiple
                  onChange={(e) => handleimage('additionalDoc2', e, 'front')}
                  style={{ display: 'none' }}
                />
              </Item>
              <Item mt={1} className={classes.uploadDocContainer}>
                {sendData?.additionalDoc2 !== undefined &&
                sendData?.additionalDoc2.length >= 1 &&
                sendData?.additionalDoc2[0] !== undefined
                  ? docsUi('', sendData?.additionalDoc2, 'additionalDoc2')
                  : ''}
              </Item>
              <Typography
                className={classes.sampleFileText}
                sx={{ fontSize: '12px' }}
                onClick={() => handleSampleDocsModal('photo')}>
                Click here to view the sample file.
              </Typography>
            </Item> */}

            {/* <Item mt={2} md={12} xs={12} lg={5.8}>
              <Item className={classes.docContainer}>
                <Typography>Visa</Typography>
                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={handleVisa}>
                  Upload
                </Button>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenVisaInput}
                  multiple
                  onChange={(e) => handleimage('visa', e, 'front')}
                  style={{ display: 'none' }}
                />
              </Item>
              <Item mt={1} className={classes.uploadDocContainer}>
                {sendData?.visaLink !== undefined &&
                sendData?.visaLink.length >= 1 &&
                sendData?.visaLink[0] !== undefined &&
                sendData?.visaLink[0] !== null
                  ? docsUi('', sendData?.visaLink, 'visa')
                  : ''}
              </Item>
              {/* <Typography
                className={classes.sampleFileText}
                sx={{ fontSize: '12px' }}
                onClick={() => handleSampleDocsModal('visa')}>
                Click here to view the sample file.
              </Typography>
            </Item>   */}

            {/* {store?.corporateId !== theme.corporate.mliCorporateId && (
              <Item mt={2} md={12} xs={12} lg={5.8}>
                <Item className={classes.docContainer}>
                  <Typography>Travel Insurance</Typography>
                  <Button
                    variant="outlined"
                    style={{ borderRadius: '5px', height: '25px' }}
                    onClick={handleTravalInsurance}>
                    Upload
                  </Button>
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    ref={hiddenTravalInsuranceInput}
                    multiple
                    onChange={(e) => handleimage('travalInsurance', e, 'front')}
                    style={{ display: 'none' }}
                  />
                </Item>
                <Item mt={1} className={classes.uploadDocContainer}>
                  {sendData?.travelInsuranceLink !== undefined &&
                  sendData?.travelInsuranceLink.length >= 1 &&
                  sendData?.travelInsuranceLink[0] !== undefined
                    ? docsUi('', sendData?.travelInsuranceLink, 'travelInsurance')
                    : ''}
                </Item>
              </Item>
            )}

            {store?.corporateId !== theme.corporate.mliCorporateId && (
              <Item mt={2} md={12} xs={12} lg={5.8}>
                <Item className={classes.docContainer}>
                  <Typography>Health Insurance</Typography>
                  <Button
                    variant="outlined"
                    style={{ borderRadius: '5px', height: '25px' }}
                    onClick={handleHealthInsurance}>
                    Upload
                  </Button>
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    ref={hiddenHealthInsuranceInput}
                    multiple
                    onChange={(e) => handleimage('healthInsurance', e, 'front')}
                    style={{ display: 'none' }}
                  />
                </Item>
                <Item mt={1} className={classes.uploadDocContainer}>
                  {sendData?.healthInsuranceLink !== undefined &&
                  sendData?.healthInsuranceLink.length >= 1 &&
                  sendData?.healthInsuranceLink[0] !== undefined
                    ? docsUi('', sendData?.healthInsuranceLink, 'healthInsurance')
                    : ''}
                </Item>
              </Item>
            )}

            <Item mt={2} md={12} xs={12} lg={5.8}>
              <Item className={classes.docContainer}>
                <Typography>Additional Doc1</Typography>
                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={handleAdditionDocs1}>
                  Upload
                </Button>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenAdditionDocs1Input}
                  multiple
                  onChange={(e) => handleimage('additionalDoc1', e, 'front')}
                  style={{ display: 'none' }}
                />
              </Item>
              <Item mt={1} className={classes.uploadDocContainer}>
                {sendData?.additionalDoc1 !== undefined &&
                sendData?.additionalDoc1.length >= 1 &&
                sendData?.additionalDoc1[0] !== undefined
                  ? docsUi('', sendData?.additionalDoc1, 'additionalDoc1')
                  : ''}
              </Item>
            </Item>

            <Item mt={2} md={12} xs={12} lg={5.8}>
              <Item className={classes.docContainer}>
                <Typography>Vaccination</Typography>

                <Button
                  variant="outlined"
                  style={{ borderRadius: '5px', height: '25px' }}
                  onClick={
                    vacStatus !== '1st'
                      ? vacStatus === '2nd'
                        ? handleVaccination2ndDose
                        : handleBooster
                      : handleVaccination1stDose
                  }>
                  Upload
                </Button>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenVaccination1stDoseInput}
                  multiple
                  onChange={(e) => handleimage('vaccination1stDose', e, '')}
                  style={{ display: 'none' }}
                />
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenVaccination2ndDoseInput}
                  multiple
                  onChange={(e) => handleimage('vaccination2ndDose', e, '')}
                  style={{ display: 'none' }}
                />
                <input
                  type="file"
                  accept="image/*,.pdf"
                  ref={hiddenVaccinationBoosterInput}
                  multiple
                  onChange={(e) => handleimage('vaccinationBooster', e, '')}
                  style={{ display: 'none' }}
                />
              </Item>
              <Item md={8} className={classes.docContainer} mt={0.5}>
                {vacStatusArray?.map((item) => {
                  return (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        cursor: 'pointer',
                        color: vacStatus === item.value ? 'red' : 'black'
                      }}
                      onClick={() => setVacStatus(item.value)}>
                      {item.name}
                    </Typography>
                  );
                })}
              </Item>
              {vacStatus !== '1st' ? (
                vacStatus === '2nd' ? (
                  <Item mt={1} className={classes.uploadDocContainer}>
                    {sendData?.vaccination2ndDoseLink !== undefined &&
                    sendData?.vaccination2ndDoseLink.length >= 1 &&
                    sendData?.vaccination2ndDoseLink[0] !== undefined
                      ? docsUi('', sendData?.vaccination2ndDoseLink, '2ndDose')
                      : ''}
                  </Item>
                ) : (
                  <Item mt={1} className={classes.uploadDocContainer}>
                    {sendData?.vaccinationBoosterLink !== undefined &&
                    sendData?.vaccinationBoosterLink.length >= 1 &&
                    sendData?.vaccinationBoosterLink[0] !== undefined
                      ? docsUi('', sendData?.vaccinationBoosterLink, 'boosterDose')
                      : ''}
                  </Item>
                )
              ) : (
                <Item mt={1} className={classes.uploadDocContainer}>
                  {sendData?.vaccination1stDoseLink !== undefined &&
                  sendData?.vaccination1stDoseLink.length >= 1 &&
                  sendData?.vaccination1stDoseLink[0] !== undefined
                    ? docsUi('', sendData?.vaccination1stDoseLink, '1stDose')
                    : ''}
                </Item>
              )}
            </Item> */}
          </Item>
        </Container>
      </React.Fragment>
    );
  }

  // user confimed

  function formDetails() {
    return (
      <React.Fragment>
        <Container
          className={classes.formDetailContainer}
          sx={{ maxHeight: '62vh', overflow: 'auto' }}>
          <Item align="center" m={3} md={12}>
            <Typography sx={{ fontSize: '1.1rem', fontWeight: '600' }}>
              Please verify your details
            </Typography>
            <Typography sx={{ fontSize: '0.9rem' }}>
              Your details were automatically filled up Please Verify your information .
            </Typography>
          </Item>

          <Item mt={2} md={12} xs={12} lg={12}>
            <Typography>Aadhaar Card</Typography>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
              <Item md={5.8} xs={12} lg={5.8} mt={1} className={classes.previewImg}>
                <img
                  src={sendData?.aadhaarFrontLink?.[0]?.uri}
                  alt={sendData?.aadhaarFrontLink?.[0]?.type}
                  className={classes.docImage}
                />
              </Item>
              <Item md={5.8} xs={12} lg={5.8} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Item mt={1}>
                  <TextField
                    variant="outlined"
                    name="aadharNo"
                    label="Aadhaar No"
                    value={sendData.aadharNo}
                    // required={true}
                    type="text"
                    onChange={(e) => handleStateChange('aadharNo', e.target.value)}
                    fullWidth
                  />
                </Item>
                <Item mt={1}>
                  {/* <TextField
                variant="outlined"
                name="aadhaarAddress"
                // required={true}
                label="Address"
                value={sendData.aadhaarAddress}
                onChange={(e) => handleStateChange('aadhaarAddress', e.target.value)}
                type="text"
                fullWidth
              /> */}
                </Item>
              </Item>
            </Item>
          </Item>
          <Item mt={2} md={12} xs={12} lg={12}>
            <Typography>PAN Card</Typography>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
              <Item md={5.8} xs={12} lg={5.8} mt={1} className={classes.previewImg}>
                <img
                  src={sendData?.panCardLink?.[0]?.uri}
                  alt={sendData?.panCardLink?.[0]?.type}
                  className={classes.docImage}
                />
              </Item>
              <Item md={5.8} xs={12} lg={5.8} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    name="panNo"
                    value={sendData.panNo}
                    // required={true}
                    type="text"
                    label="PAN No"
                    onChange={(e) => handleStateChange('panNo', e.target.value)}
                    fullWidth
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    name="panDOB"
                    // required={true}
                    label="DOB"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={getDocsDate(sendData.panDOB)}
                    onChange={(e) => handleStateChange('panDOB', e.target.value)}
                    type="date"
                    fullWidth
                  />
                </Item>
              </Item>
            </Item>
          </Item>

          <Item mt={2} md={12} xs={12} lg={12}>
            <Typography>Passport</Typography>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
              <Item md={5.8} xs={12} lg={5.8} mt={1} className={classes.previewImg}>
                <img
                  src={sendData?.passportFrontLink?.[0]?.uri}
                  alt={sendData?.passportFrontLink?.[0]?.type}
                  className={classes.docImage}
                />
              </Item>
              <Item md={5.8} xs={12} lg={5.8} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Item mt={1}>
                  <TextField
                    variant="outlined"
                    name="passportNo"
                    // required={true}
                    type="text"
                    label="Passport No"
                    fullWidth
                    value={sendData.passportNo}
                    onChange={(e) => handleStateChange('passportNo', e.target.value)}
                  />
                </Item>
                <Item mt={2}>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      if (value === null) {
                        handleStateChange('passportCountry', '');
                      }
                      handleStateChange('passportCountry', value);
                    }}
                    options={countryList}
                    value={sendData.passportCountry}
                    color="#134CDF"
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="Country"
                        required={
                          sendData?.passportFrontLink &&
                          sendData?.passportFrontLink[0] !== undefined
                        }
                        label="Country"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  />
                </Item>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    name="passportExpiryDate"
                    // required={true}
                    type="date"
                    label="Expiry Date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => handleStateChange('passportExpiryDate', e.target.value)}
                    value={getDocsDate(sendData.passportExpiryDate)}
                  />
                </Item>
              </Item>
            </Item>
          </Item>

          {/* <Item mt={2} md={12} xs={12} lg={12}>
            <Typography>Visa</Typography>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
              <Item md={5.8} xs={12} lg={5.8} mt={1} className={classes.previewImg}>
                <img
                  src={sendData?.visaLink?.[0]?.uri}
                  alt={sendData?.visaLink?.[0]?.type}
                  className={classes.docImage}
                />
              </Item>
              <Item md={5.8} xs={12} lg={5.8} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    name="visaNo"
                    type="text"
                    fullWidth
                    label="Visa No"
                    value={sendData.visaNo}
                    onChange={(e) => handleStateChange('visaNo', e.target.value)}
                  />
                </Item>
                <Item mt={2}>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      if (value === null) {
                        handleStateChange('visaCountry', '');
                      }
                      handleStateChange('visaCountry', value);
                    }}
                    options={countryList}
                    value={sendData.visaCountry}
                    color="#134CDF"
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="Country"
                        required={sendData?.visaLink?.length > 0}
                        label="Country"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  />
                </Item>

                <Item mt={2}>
                  <TextField
                    variant="outlined"
                    name="visaExpiryDate"
                    // required={true}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    label="Expiry Date"
                    value={getDocsDate(sendData.visaExpiryDate)}
                    onChange={(e) => handleStateChange('visaExpiryDate', e.target.value)}
                  />
                </Item>

                <Item mt={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Visa Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="visaType"
                      fullWidth
                      label="Visa Type"
                      value={sendData.visaType}
                      onChange={(e) => handleStateChange('visaType', e.target.value)}
                      l>
                      <MenuItem value="work">Work</MenuItem>
                      <MenuItem value="tourist">Tourist</MenuItem>
                      <MenuItem value="immigration">Immigration</MenuItem>
                      <MenuItem value="student">Student</MenuItem>
                    </Select>
                  </FormControl>
                </Item>
                <Item mt={2}>
                  <Typography>Entry level</Typography>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="visaEntry"
                      value={sendData.visaEntry}
                      onChange={(e) => handleStateChange('visaEntry', e.target.value)}>
                      <FormControlLabel value={'single'} control={<Radio />} label="Single entry" />
                      <FormControlLabel
                        value={'multiple'}
                        control={<Radio />}
                        label="Multiple entry"
                      />
                    </RadioGroup>
                  </FormControl>
                </Item>
              </Item>
            </Item>
          </Item> */}
          {/* 
          {store?.corporateId !== theme.corporate.mliCorporateId && (
            <Item mt={2} md={12}>
              <Typography>Travel Insurance</Typography>
              <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
                <Item >
                  <TextField
                    variant="outlined"
                    name="travelInsurance"
                    type="text"
                    label="Registration No"
                    fullWidth
                    value={sendData.travelInsurance}
                    onChange={(e) => handleStateChange('travelInsurance', e.target.value)}
                  />
                </Item>

                <Item md={5.8} xs={12} lg={5.8}>
                  <TextField
                    variant="outlined"
                    name="travelInsuranceExpiryDate"
                    type="date"
                    fullWidth
                    label="Expiry Date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={getDocsDate(sendData.travelInsuranceExpiryDate)}
                    onChange={(e) => handleStateChange('travelInsuranceExpiryDate', e.target.value)}
                  />
                </Item>
                <Item md={5.8} xs={12} lg={5.8}>
                  <Autocomplete
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      if (value === null) {
                        handleStateChange('travelInsuranceCountry', '');
                      }
                      handleStateChange('travelInsuranceCountry', value);
                    }}
                    options={countryList}
                    value={sendData.travelInsuranceCountry}
                    color="#134CDF"
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="Country"
                        required={sendData?.travelInsuranceLink > 0}
                        label="Country"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  />
                </Item>
                <Item md={12} xs={12} lg={5.8}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Company Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="travelInsuranceCompanyName"
                      value={sendData.travelInsuranceCompanyName}
                      onChange={(e) =>
                        handleStateChange('travelInsuranceCompanyName', e.target.value)
                      }
                      fullWidth
                      label="Company Name">
                      <MenuItem value="policybazaar">Policy Bazaar</MenuItem>
                      <MenuItem value="lic">LIC</MenuItem>
                      <MenuItem value="microsoft">Microsoft</MenuItem>
                      <MenuItem value="google">Google</MenuItem>
                    </Select>
                  </FormControl>
                </Item>
              </Item>
            </Item>
          )} */}

          {/* {store?.corporateId !== theme.corporate.mliCorporateId && (
            <Item mt={2} md={12}>
              <Typography>Health Insurance</Typography>
              <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
                <Item  mt={1}>
                  <TextField
                    variant="outlined"
                    name="healthInsurance"
                    // required={true}
                    type="text"
                    fullWidth
                    label="Registration No"
                    value={sendData.healthInsurance}
                    onChange={(e) => handleStateChange('healthInsurance', e.target.value)}
                  />
                </Item>
                <Item  mt={1}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Company Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="healthInsuranceCompanyName"
                      value={sendData.healthInsuranceCompanyName}
                      onChange={(e) =>
                        handleStateChange('healthInsuranceCompanyName', e.target.value)
                      }
                      fullWidth
                      label="Company Name">
                      <MenuItem value="policybazaar">Policy Bazaar</MenuItem>
                      <MenuItem value="lic">LIC</MenuItem>
                      <MenuItem value="microsoft">Microsoft</MenuItem>
                      <MenuItem value="google">Google</MenuItem>
                    </Select>
                  </FormControl>
                </Item>

                <Item md={5.8} xs={12} lg={3.8} mt={1}>
                  <TextField
                    variant="outlined"
                    name="healthInsuranceExpiryDate"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Expiry Date"
                    value={getDocsDate(sendData.healthInsuranceExpiryDate)}
                    onChange={(e) => handleStateChange('healthInsuranceExpiryDate', e.target.value)}
                  />
                </Item>
              </Item>
            </Item>
          )} */}

          {/* <Item mt={2} md={12}>
            <Typography>Vaccination</Typography>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12}>
              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Vaccination Status</InputLabel>
                  <Select
                    label="Vaccination Status"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="vaccineStatus1"
                    fullWidth
                    value={sendData.vaccineStatus1}
                    onChange={(e) => handleStateChange('vaccineStatus1', e.target.value)}>
                    <MenuItem value="1st dose">1st dose</MenuItem>
                    <>
                      <MenuItem value="2nd dose">2nd dose</MenuItem>
                      <MenuItem value="Booster">Booster</MenuItem>
                      <MenuItem value="No dose">No dose</MenuItem>
                    </>
                  </Select>
                </FormControl>
              </Item>
              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Vaccine Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Vaccine Type"
                    name="vaccineType1"
                    fullWidth
                    value={sendData?.vaccineType1}
                    onChange={(e) => handleStateChange('vaccineType1', e.target.value)}>
                    <MenuItem value="COVISHIELD">Covishield</MenuItem>
                    <MenuItem value="COVAXIN"> Covaxin</MenuItem>
                    <MenuItem value="CORBEVAX">Corbevax</MenuItem>
                    <MenuItem value="SPUTNIK">Sputnik V</MenuItem>
                  </Select>
                </FormControl>
              </Item>

              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <TextField
                  variant="outlined"
                  name="vaccineDate1"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Vaccine Date"
                  value={getDocsDate(sendData.vaccineDate1)}
                  onChange={(e) => handleStateChange('vaccineDate1', e.target.value)}
                />
              </Item>
            </Item>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12} mt={1}>
              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Vaccination Status</InputLabel>
                  <Select
                    label="Vaccination Status"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="vaccineStatus2"
                    fullWidth
                    value={sendData.vaccineStatus2}
                    onChange={(e) => handleStateChange('vaccineStatus2', e.target.value)}>
                    <MenuItem value="2nd dose">2nd dose</MenuItem>
                  </Select>
                </FormControl>
              </Item>
              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Vaccine Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Vaccine Type"
                    name="vaccineType2"
                    fullWidth
                    value={sendData?.vaccineType2}
                    onChange={(e) => handleStateChange('vaccineType2', e.target.value)}>
                    <MenuItem value="COVISHIELD">Covishield</MenuItem>
                    <MenuItem value="COVAXIN"> Covaxin</MenuItem>
                    <MenuItem value="CORBEVAX">Corbevax</MenuItem>
                    <MenuItem value="SPUTNIK">Sputnik V</MenuItem>
                  </Select>
                </FormControl>
              </Item>

              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <TextField
                  variant="outlined"
                  name="vaccineDate2"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Vaccine Date"
                  value={getDocsDate(sendData.vaccineDate2)}
                  onChange={(e) => handleStateChange('vaccineDate2', e.target.value)}
                />
              </Item>
            </Item>
            <Item className={classes.inputBoxContainer} md={12} xs={12} lg={12} mt={1}>
              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Vaccination Status</InputLabel>
                  <Select
                    label="Vaccination Status"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="vaccineStatus3"
                    fullWidth
                    value={sendData.vaccineStatus3}
                    onChange={(e) => handleStateChange('vaccineStatus3', e.target.value)}>
                    <MenuItem value="Booster">Booster</MenuItem>
                  </Select>
                </FormControl>
              </Item>
              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Vaccine Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Vaccine Type"
                    name="vaccineType3"
                    fullWidth
                    value={sendData?.vaccineType3}
                    onChange={(e) => handleStateChange('vaccineType3', e.target.value)}>
                    <MenuItem value="COVISHIELD">Covishield</MenuItem>
                    <MenuItem value="COVAXIN"> Covaxin</MenuItem>
                    <MenuItem value="CORBEVAX">Corbevax</MenuItem>
                    <MenuItem value="SPUTNIK">Sputnik V</MenuItem>
                  </Select>
                </FormControl>
              </Item>

              <Item md={5.8} xs={12} lg={3.8} mt={1}>
                <TextField
                  variant="outlined"
                  name="vaccineDate3"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Vaccine Date"
                  value={getDocsDate(sendData.vaccineDate3)}
                  onChange={(e) => handleStateChange('vaccineDate3', e.target.value)}
                />
              </Item>
            </Item>
          </Item> */}
        </Container>
      </React.Fragment>
    );
  }

  // render form
  let renderStep;

  if (dependentStatus === 'Add Dependent') {
    const render = () => {
      let content = null;
      switch (step) {
        case 1:
          content = <div>{yourDetailsForm()}</div>;
          break;
        case 2:
          content = <div>{documents()}</div>;
          break;
        case 3:
          content = <div>{formDetails()}</div>;
          break;
        default:
          content = <div>Error</div>;
          break;
      }
      return content;
    };
    renderStep = render;
  } else {
    const render = () => {
      let content = null;
      switch (step) {
        case 1:
          content = (
            <div>
              <DependentViewPersonal indiviualUserData={indiviualUserData} />
            </div>
          );
          break;
        case 2:
          content = (
            <div>
              <DependentDocs indiviualUserData={indiviualUserData} />
            </div>
          );
          break;
        case 3:
          content = (
            <div>
              <DocumentConfirm indiviualUserData={indiviualUserData} />
            </div>
          );
          break;
        default:
          content = <div>Error</div>;
          break;
      }
      return content;
    };
    renderStep = render;
  }

  const handleSampleDocsModal = (status) => {
    setSampleDocsType(status);
    setIsOpenSampleDocsModal(true);
  };
  const handleSampleDocsClose = () => {
    setIsOpenSampleDocsModal(false);
  };

  return (
    <React.Fragment>
      {isOpenSampleDocsModal ? (
        <Item p={1}>
          <Item>
            <SampleDocsFile
              sampleDocsType={sampleDocsType}
              handleSampleDocsClose={handleSampleDocsClose}
            />
          </Item>
        </Item>
      ) : (
        <Container direction="column" wrap="nowrap" className={classes.mainContainer}>
          {dependentStatus === 'Add Dependent' ? (
            <Typography sx={{ fontSize: '1.3rem', fontWeight: '600' }}>{formType}</Typography>
          ) : (
            <Item sx={{ display: 'flex', gap: '10px', alignItems: 'center' }} md={12}>
              <Item sx={{ display: 'inline-flex', ml: 'auto' }} md={5.8} xs={12} lg={5}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="demo-simple-select-outlined-label">Family</InputLabel>
                  <Select
                    name="Family"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedFamilyMember}
                    onChange={(e) => setSelectedFamilyMember(e.target.value)}
                    label="Region">
                    {allFamilyMembers?.map((item, index) => {
                      return (
                        <MenuItem value={item?._id}>{ConvertToUpperCase(item?.fullName)}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Item>
              <Item>
                {allFamilyMembers?.length > 0 && (
                  <Button style={{ ...theme.button.cancel }} onClick={handleEdit}>
                    Edit
                  </Button>
                )}
              </Item>
            </Item>
          )}
          <Item className={classes.stepperContainer} md={12} xs={12} lg={12}>
            {labels.map((label, index) => (
              <Item md={12} lg={4} xs={12} mt={1}>
                <Item
                  className={classes.stepper}
                  style={{
                    borderBottom:
                      step === index + 1
                        ? `3px solid #${theme.palette.primary.main}`
                        : '1px solid #E1E1E1'
                  }}
                  onClick={() =>
                    dependentStatus === 'Add Dependent'
                      ? allFamilyMembers?.length > 0
                        ? setStep(index + 1)
                        : setStep(1)
                      : setStep(index + 1)
                  }>
                  {dependentStatus === 'Add Dependent' ? (
                    <span className={classes.stepperBlock}>
                      {step >= index + 2 ? (
                        <CheckCircleOutlineIcon style={{ color: 'red' }} />
                      ) : (
                        <RadioButtonUncheckedRoundedIcon />
                      )}
                    </span>
                  ) : (
                    ''
                  )}
                  <span>
                    {dependentStatus !== 'Add Dependent'
                      ? label === 'Confirm'
                        ? 'Overview'
                        : label
                      : label}
                  </span>
                </Item>
              </Item>
            ))}
          </Item>
          <form onSubmit={handleSubmit}>
            <Item>{renderStep()}</Item>

            <Item sx={{ float: 'right', m: '30px 0px 10px 0px' }}>
              <Button
                onClick={prevStep}
                sx={{ display: step === 1 ? 'none' : 'inline', mr: '10px' }}
                variant="outlined">
                previous
              </Button>

              {isLoading ? (
                <Button
                  className={classes.searchButton}
                  style={{
                    backgroundColor: theme.palette.primary.tableHeadingColor,
                    color: '#FFFFFF'
                  }}>
                  <CircularProgress size={25} style={{ color: 'white', marginRight: '10px' }} />
                  procesing
                </Button>
              ) : dependentStatus === 'Add Dependent' ? (
                <Button
                  type="submit"
                  style={{
                    ...theme.button.submit,
                    backgroundColor: theme.palette.primary.main
                  }}>
                  {step < 3 ? 'Next' : 'Submit'}
                </Button>
              ) : (
                ''
              )}
            </Item>
          </form>
        </Container>
      )}
    </React.Fragment>
  );
}

export default withStyles(styles)(Dependent);
