import React, { useState, useEffect } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import AddAgent from './AddAgentDummy';
import Dependent from './Dependent';
import Packges from './Package';
import Confimation from './Confimation';
import { useStateValue } from '../../utils/store';
import { API_GET, API_HANDLE_ERROR } from '../../utils/api';
import SelfDeclerationFrom from './SelfDeclerationFrom';
import theme from '../../utils/theme';
import FamilyMemberConfirmation from './FamilyMemberConfirmation';
import SorryFrom from './SorryFrom';
import DecleartionForm from './DecleartionForm';
import GroupEditModal from './GroupEditModal';
import Dialog from '../../components/atom/Dialog';

const { withStyles, Grid, Typography, FormControl, Select, MenuItem, useMediaQuery } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid container {...props} />;

const AgentForm = ({ classes, handleClose }) => {
  const [store, dispatch] = useStateValue();
  const [formType, setFromType] = useState('Agent Registration Form');
  const [step, setstep] = useState(1);
  const [userData, setUserData] = useState({});
  const [userId, setuserId] = useState();
  const [dependentStatus, setDependentStatus] = useState('Add Dependent');
  const [isEdit, setIsEdit] = useState('add');
  const [allPackageIds, setAllPackageIds] = useState([]);
  const [data, setData] = useState({});
  const [status, setStatus] = useState('add');
  const [isOpenDeclerationFrom, setIsOpenDeclerationFrom] = useState(false);
  const [declerationData, setDeclerationData] = useState({});
  const scrrenSizeSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const [stepStatus, setStepStatus] = useState('');
  const [isOpenEmptyGroupModal, setIsOpenEmptyGroupModal] = useState(false);
  const [isOpeneDecleartionModal, setIsOpenDeclerationModal] = useState(false);
  const [ParentId, setParentId] = useState();
  const [isOpenNomination, setIsOpenNomination] = useState(true);
  const [nominationData, setNominationData] = useState({});

  const getNominated = () => {
    API_GET(`travelPackage/getNominatedEmployee`)
      .then((res) => {
        if (res?.data) {
          setIsOpenNomination(res?.data?.userResponseStatus);
          setNominationData(res?.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
        // API_HANDLE_ERROR(err, dispatch);
      });
  };
  useEffect(() => {
    getNominated();
  }, []);

  const handleStep = (step, data, Id, status) => {
    setstep(step);
    setUserData(data);
    setuserId(Id);
    setStepStatus(status);
  };

  const handleParentId = (id) => {
    setParentId(id);
  };

  useEffect(() => {
    API_GET('user/getUserDetails')
      .then((res) => {
        setParentId(res?.data?.user?._id);
        setData(res?.data?.user);
        setStatus('edit');
        setuserId(res?.data?.user?._id);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  }, [dispatch, userId, step]);

  const handleDependent = () => {
    setstep(1);
    setFromType('Family Registration Form');
    setIsEdit('add');
  };

  const handleDependentStatus = (e) => {
    setDependentStatus(e.target.value);
  };

  const handleCloseSorryFrom = () => {
    setstep(1);
    setFromType('Agent Registration Form');
    setIsOpenEmptyGroupModal(false);
  };

  // check 1st time
  const handleDependentClick = () => {
    if (userId) {
      setFromType('Family Registration Form');
    } else {
      dispatch({
        type: 'ALERT_OPEN',
        severity: 'error',
        isOpen: true,
        label: 'Please Add Agent'
      });
    }
  };

  const EditDependent = () => {
    setFromType('Family Registration Form');
    setDependentStatus('Add Dependent');
    setIsEdit('edit');
  };

  const addEditDepedentStatus = () => {
    setIsEdit('edit');
  };

  const renderStep = () => {
    if (formType === 'Agent Registration Form') {
      return (
        <AddAgent
          handleClose={handleClose}
          status={status}
          data={data}
          formType={formType}
          handleStep={handleStep}
          handleParentId={handleParentId}
        />
      );
    } else if (formType === 'Family Registration Form') {
      return (
        <Dependent
          handleClose={handleClose}
          status={status}
          resultGetData={data}
          formType={formType}
          dataId={ParentId}
          dependentStatus={dependentStatus}
          handleDependentClick={handleDependentClick}
          EditDependent={EditDependent}
          handleStep={handleStep}
          isEdit={isEdit}
          addEditDepedentStatus={addEditDepedentStatus}
        />
      );
    } else {
      return (
        <Packges
          nominationData={nominationData}
          handleClose={handleClose}
          status={status}
          resData={data}
          declerationData={declerationData}
          formType={formType}
          userId={userId}
          allPackageIds={allPackageIds}
          handleDecleartion={handleDecleartion}
          handleIsOpenDecleartionModal={handleIsOpenDecleartionModal}
        />
      );
    }
  };

  // check packages

  const getAllPackageId = () => {
    userId &&
      API_GET(`travelPackage/getAllNominatedEmployees?userId=${userId}`)
        .then((res) => {
          setAllPackageIds(res?.data);
        })
        .catch((err) => {
          API_HANDLE_ERROR(err, dispatch);
        });
  };

  useEffect(() => {
    getAllPackageId();
  }, [userId]);

  const handlePackage = () => {
    if (allPackageIds.length === 0) {
      setIsOpenEmptyGroupModal(true);
    } else {
      setstep('');
      setFromType('Group');
    }
  };

  const handleDecleartionFrom = () => {
    setIsOpenDeclerationFrom(false);
  };

  const handleDecleartion = (item) => {
    setIsOpenDeclerationFrom(true);
    setDeclerationData(item?.formData);
  };

  const handleIsOpenDecleartionModal = (data) => {
    setIsOpenDeclerationModal(true);
    setDeclerationData(data);
  };

  const handleClickDecleration = () => {
    setIsOpenDeclerationModal(false);
    setIsOpenDeclerationFrom(true);
  };
  return (
    <React.Fragment>
      {isOpenDeclerationFrom ? (
        <Container direction="column" className={classes.formMainContainer}>
          <SelfDeclerationFrom
            handleClose={handleDecleartionFrom}
            declerationData={declerationData}
            userId={userId}
            getAllPackageId={getAllPackageId}
          />
        </Container>
      ) : isOpenEmptyGroupModal ? (
        <Item className={classes.formContainer1} md={12} xs={12} lg={12}>
          <Item md={12}>
            <SorryFrom handleCloseSorryFrom={handleCloseSorryFrom} />
          </Item>
        </Item>
      ) : isOpeneDecleartionModal ? (
        <Item className={classes.formContainer1} md={12} xs={12} lg={12}>
          <Item md={12}>
            <DecleartionForm handleClickDecleration={handleClickDecleration} />
          </Item>
        </Item>
      ) : (
        <React.Fragment>
          {scrrenSizeSmall ? ( // check for small screen size
            <Container direction="column" className={classes.formMainContainer}>
              <Item className={classes.mainContainer}>
                <Item className={classes.title} md={12} lg={12} xs={12}>
                  <Item>
                    <img
                      src="https://drorlabstorage.blob.core.windows.net/dror/lythouse-logo.png"
                      alt="logo"
                      width="124px"
                      height="35px"
                      style={{ filter: 'brightness(0) invert(1)' }}
                    />
                  </Item>
                  {step !== 4 ? (
                    <Item
                      sx={{
                        display: 'flex',
                        gap: '30px',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                      }}>
                      <Typography
                        sx={{
                          fontSize: '1.2rem',
                          fontWeight: '700'
                        }}
                        className={classes.head}
                        onClick={() => setFromType('Agent Registration Form')}>
                        {store?.role === 'agent' ? 'Agent' : 'Employee'}
                      </Typography>
                      <Item sx={{ width: '200px' }} onClick={() => handleDependentClick()}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            size="small"
                            sx={{
                              color: '#FFFFFF',
                              fontWeight: '600',
                              fontSize: '1.2rem',
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 }
                            }}
                            value={dependentStatus}
                            onChange={(e) => handleDependentStatus(e)}>
                            <MenuItem value="Add Dependent" onClick={() => setIsEdit('add')}>
                              Add Dependent
                            </MenuItem>
                            <MenuItem value="View Dependent">View Dependent</MenuItem>
                          </Select>
                        </FormControl>
                      </Item>

                      <Typography
                        sx={{ fontSize: '1.2rem', fontWeight: '700' }}
                        className={classes.head}
                        onClick={() => handlePackage()}>
                        Group
                      </Typography>
                    </Item>
                  ) : (
                    ''
                  )}
                </Item>

                <Item className={classes.formContainer1} md={12} xs={12} lg={12}>
                  {step === 4 ? (
                    <Item md={12}>
                      {stepStatus === 'agent' ? (
                        <Confimation
                          resUserData={userData}
                          userId={userId}
                          status={status}
                          handleDependent={handleDependent}
                        />
                      ) : (
                        <FamilyMemberConfirmation
                          handleDependent={handleDependent}
                          handlePackage={handlePackage}
                        />
                      )}
                    </Item>
                  ) : (
                    <Item md={12} xs={12} lg={12}>
                      {renderStep(handleDecleartion)}
                    </Item>
                  )}
                </Item>
              </Item>
            </Container>
          ) : (
            <Container direction="column" className={classes.formMainContainer}>
              <Item>
                <Item className={classes.title1} md={12} lg={12} xs={12}>
                  {step !== 4 ? (
                    <Item
                      sx={{
                        display: 'flex',
                        gap: '25px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid grey'
                      }}>
                      <Item
                        md={5}
                        lg={5}
                        xs={5}
                        sx={{
                          borderBottom:
                            formType === 'Agent Registration Form' ? '2px solid red' : 'none'
                        }}
                        p={0.8}>
                        <Typography
                          style={{
                            fontSize: '1.1rem',
                            fontWeight: '500',
                            color: formType === 'Agent Registration Form' ? 'red' : 'black'
                          }}
                          className={classes.head}
                          onClick={() => setFromType('Agent Registration Form')}>
                          {store?.role === 'agent' ? 'Agent' : 'Employee'}
                        </Typography>
                      </Item>

                      <Item
                        onClick={() => handleDependentClick()}
                        sx={{
                          width: '200px'
                        }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            size="small"
                            sx={{
                              fontWeight: '500',
                              fontSize: '1.1rem',
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': {
                                border: 0
                              }
                            }}
                            value={dependentStatus}
                            onChange={(e) => setDependentStatus(e.target.value)}>
                            <MenuItem value="Add Dependent" onClick={() => setIsEdit('add')}>
                              Add Dependent
                            </MenuItem>
                            <MenuItem value="View Dependent">View Dependent</MenuItem>
                          </Select>
                        </FormControl>
                      </Item>

                      <Item
                        md={5}
                        lg={5}
                        xs={5}
                        sx={{
                          borderBottom:
                            formType === 'Group' || formType === 'View Dependent'
                              ? '2px solid red'
                              : 'none'
                        }}
                        p={0.8}>
                        <Typography
                          sx={{
                            fontSize: '1.1rem',
                            fontWeight: '500',
                            color: formType === 'Group' ? 'red' : 'black'
                          }}
                          className={classes.head}
                          onClick={() => handlePackage()}>
                          Group
                        </Typography>
                      </Item>
                    </Item>
                  ) : (
                    ''
                  )}
                </Item>

                <Item className={classes.formContainer1} md={12} xs={12} lg={12}>
                  {step === 4 ? (
                    <Item md={12}>
                      {stepStatus === 'agent' ? (
                        <Confimation
                          resUserData={userData}
                          userId={userId}
                          status={status}
                          handleDependent={handleDependent}
                        />
                      ) : (
                        <FamilyMemberConfirmation
                          handleDependent={handleDependent}
                          handlePackage={handlePackage}
                        />
                      )}
                    </Item>
                  ) : (
                    <Item md={12} xs={12} lg={12}>
                      {renderStep(handleDecleartion)}
                    </Item>
                  )}
                </Item>
              </Item>
            </Container>
          )}
        </React.Fragment>
      )}
      {!isOpenNomination && (
        <Dialog
          isOpen={!isOpenNomination}
          children={<GroupEditModal data={nominationData} getNominated={getNominated} />}></Dialog>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(AgentForm);
