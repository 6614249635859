import React, { useState } from 'react';
import { Components, Icons } from '../../utils/material-ui';
import { useStateValue } from '../../utils/store';
import { API_POST, API_HANDLE_ERROR } from '../../utils/api';
import styles from './styles';
import { useNavigate } from 'react-router-dom';
import theme from '../../utils/theme';

const { withStyles, Grid, TextField, Button, Typography, IconButton, InputAdornment } = Components;
const { MailOutlineIcon, VisibilityOffIcon, VisibilityIcon } = Icons;
const Container = (props) => <Grid container {...props} />;
const Item = (props) => <Grid item {...props} />;

const PasswordReset = ({ classes, handleBack, handleCloseAll }) => {
  const [, dispatch] = useStateValue();
  const [loadingSendOtp, setLoadingOtp] = useState(false);
  const [loadingPasswordReset, setLoadingResetPassword] = useState(false);

  const navigate = useNavigate();

  const [otpSent, setOtpSent] = useState(false);

  const [userData, setUserData] = useState({ email: '', otp: '', password1: '', password2: '' });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword0, setShowPassword0] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePassword0 = () => {
    setShowPassword0(!showPassword0);
  };

  const handleTogglePassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleStateChange = (key, value) => {
    setUserData((prevState) => {
      prevState[`${key}`] = value;
      return { ...prevState };
    });
  };

  const onHandleConfirm = (e) => {
    e.preventDefault();
    setLoadingOtp(true);
    API_POST('auth/getPasswordResetOtp', { email: userData.email })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message || 'Success'
        });
        setOtpSent(true);
        setLoadingOtp(false);
      })
      .catch((err) => {
        setLoadingOtp(false);
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  const onHandleReset = (e) => {
    e.preventDefault();
    if (userData.password1 !== userData.password2) {
      API_HANDLE_ERROR('passwords dont match', dispatch);
    }
    setLoadingResetPassword(true);
    API_POST('auth/resetPassword', {
      email: userData.email,
      otp: userData.otp,
      password: userData.password2
    })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: 'Password Reset Success, Please Login'
        });
        navigate('/');
        handleCloseAll();
        setLoadingResetPassword(false);
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
        setLoadingResetPassword(false);
      });
  };

  const RequestComponent = () => {
    return (
      <form onSubmit={onHandleConfirm} style={{ marginTop: theme.spacing(6) }}>
        <Container direction={'column'} spacing={4}>
          <Item>
            <Typography mb={0.5} sx={{ color: '#555555' }}>
              Email Id
            </Typography>
            <TextField
              variant="outlined"
              name="email"
              required={true}
              className={classes.inputBox}
              placeholder="Enter your register mail"
              size="small"
              type="text"
              fullWidth
              value={userData.email}
              onChange={(e) => {
                handleStateChange('email', e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <div className={classes.indoIcon}>
                    <InputAdornment position="end">
                      <IconButton className={classes.squareButton}>
                        <MailOutlineIcon style={{ color: '#FFFFFF' }} />
                      </IconButton>
                    </InputAdornment>
                  </div>
                )
              }}
            />
          </Item>
          <Item>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              fullWidth
              style={{
                ...theme.button.submit,
                fontWeight: '600',
                padding: '20px 5px',
                backgroundColor: '#FF6760'
              }}
              disabled={loadingSendOtp}
              className={classes.button}>
              Send OTP
            </Button>
          </Item>
          <Item>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => handleBack()}
              style={{
                ...theme.button.submit,
                fontWeight: '600',
                padding: '20px 5px',
                backgroundColor: '#FFFFFF',
                border: `1px solid #FF6760`,
                color: '#FF6760'
              }}
              disabled={loadingSendOtp}
              className={classes.button}>
              Back
            </Button>
          </Item>
        </Container>
      </form>
    );
  };

  const OtpComponent = () => {
    return (
      <form onSubmit={onHandleReset}>
        <Container direction={'column'} spacing={3}>
          <Item>
            <Typography mb={0.5} sx={{ color: '#555555' }}>
              OTP
            </Typography>
            <TextField
              variant="outlined"
              name="otp"
              required={true}
              className={classes.inputBox}
              placeholder="Enter OTP"
              type={showPassword0 ? 'text' : 'password'}
              // label="OTP"
              size="small"
              value={userData.otp}
              onChange={(e) => handleStateChange('otp', e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <div className={classes.indoIcon}>
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword0}>
                        {showPassword0 ? (
                          <VisibilityIcon style={{ color: '#FFFFFF' }} />
                        ) : (
                          <VisibilityOffIcon style={{ color: '#FFFFFF' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </div>
                )
              }}
            />
          </Item>
          <Item>
            <Typography mb={0.5} sx={{ color: '#555555' }}>
              Password
            </Typography>
            <TextField
              variant="outlined"
              name="password"
              size="small"
              required={true}
              className={classes.inputBox}
              placeholder="Enter Password"
              type={showPassword ? 'text' : 'password'}
              // label="Password"
              value={userData.password1}
              onChange={(e) => handleStateChange('password1', e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <div className={classes.indoIcon}>
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? (
                          <VisibilityIcon style={{ color: '#FFFFFF' }} />
                        ) : (
                          <VisibilityOffIcon style={{ color: '#FFFFFF' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </div>
                )
              }}
            />
          </Item>
          <Item>
            <Typography mb={0.5} sx={{ color: '#555555' }}>
              ReEnter Password
            </Typography>
            <TextField
              variant="outlined"
              name="password"
              required={true}
              size="small"
              className={classes.inputBox}
              placeholder="Enter Password"
              type={showPassword1 ? 'text' : 'password'}
              // label="ReEnter Password"
              value={userData.password2}
              onChange={(e) => handleStateChange('password2', e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <div className={classes.indoIcon}>
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword1}>
                        {showPassword1 ? (
                          <VisibilityIcon style={{ color: '#FFFFFF' }} />
                        ) : (
                          <VisibilityOffIcon style={{ color: '#FFFFFF' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </div>
                )
              }}
            />
          </Item>
          <Item>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              fullWidth
              disabled={loadingPasswordReset}
              style={{
                ...theme.button.submit,
                fontWeight: '600',
                padding: '20px 5px',
                backgroundColor: '#FF6760'
              }}
              className={classes.button}>
              Reset Password
            </Button>
          </Item>
          <Item>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{
                ...theme.button.submit,
                fontWeight: '600',
                padding: '20px 5px',
                backgroundColor: '#FFFFFF',
                border: `1px solid #FF6760`,
                color: '#FF6760'
              }}
              className={classes.button}
              onClick={() => {
                setOtpSent(false);
                setUserData({ otp: '', password1: '', password2: '', email: userData.email });
              }}>
              Generate New OTP
            </Button>
          </Item>
        </Container>
      </form>
    );
  };

  return (
    <Item md={12} xs={12}>
      <Typography
        className={classes.login_label}
        sx={{
          fontSize: '1.2rem',
          m: '15px 0px',
          textAlign: 'center',
          letterSpacing: '0.5px',
          color: '#FF6760'
        }}>
        Forgot password / Generate New Password
      </Typography>
      {otpSent ? OtpComponent() : RequestComponent()}
    </Item>
  );
};

export default withStyles(styles)(PasswordReset);
